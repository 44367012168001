import React from "react";

export const Footer = () => {
  // const getSeal = async () => {
  //   const url = await
  // }
  return (
    <div className="bg-white p-6 flex justify-around shadow-2xl menu-wrapper">
      <blockquote>
        <p className="text-lato">www.salvadorgonmo.dev</p>

        <p className="text-lato">All rights reserved - 2022.</p>
      </blockquote>
    </div>
  );
};

export default Footer;
